import Head from 'app-basic/components/head/head';
import { EdgeCacheTTL, basicGSSPWrapper } from 'app-basic/lib/get-basic-server-side-props-wrapper';
import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { AppType } from 'pages/_app';
import NotFound from 'components/not-found';

interface IProps {
  statusCode: number;
}

const ErrorPage: NextPage<IProps> = ({ statusCode }) => {
  const [lang, setLang] = useState<string | null>(null);
  const { i18n } = useTranslation('common');

  useEffect(() => {
    const { locale } = gazetteer.getMarketByURL(window.location.pathname, CountryCodes.GB);

    i18n.reloadResources([locale], 'common').then(() => {
      setLang(locale);
    });
  }, [i18n]);

  return (
    <>
      <Head plainOgTitle title="Error" isIndexable={false} />
      {lang ? <NotFound statusCode={statusCode} lng={lang} /> : null}
    </>
  );
};

export const getServerSideProps = basicGSSPWrapper([], undefined, {
  vercelCacheControl: {
    maxAge: EdgeCacheTTL.WEEK,
    sMaxAge: EdgeCacheTTL.WEEK,
    staleWhileRevalidate: EdgeCacheTTL.MONTH,
  },
  cloudflareCacheControl: {
    maxAge: EdgeCacheTTL.DAY,
    sMaxAge: EdgeCacheTTL.DAY,
    staleWhileRevalidate: EdgeCacheTTL.WEEK,
  },
});

ErrorPage.AppType = AppType.Basic;

export default ErrorPage;
