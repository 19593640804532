import { Trans, useTranslation } from 'next-i18next';
import { Box, Container, ImageX } from '@bridebook/ui';
import { UrlHelper } from 'lib/url-helper';
import componentStyles from './not-found.style';

interface IProps {
  statusCode: number;
  lng: string;
}

const NotFound = ({ statusCode, lng }: IProps) => {
  const { t } = useTranslation('common', { lng });
  const title = t('errorPage.title') + (statusCode === 404 ? '' : t('errorPage.refresh'));
  const homepageHref = UrlHelper.index;
  const styles = componentStyles();

  return (
    <Container>
      <Box style={styles.wrapper}>
        <Box as="h1" style={styles.heading}>
          {title}
        </Box>
        <Box style={styles.imgContainer}>
          <ImageX
            src="//images.bridebook.com/assets/404-not-found.jpg?auto=compress"
            altText={t('errorPage.notFound')}
            w={660}
            h={440}
          />
          <Box style={styles.imgCaption}>{t('errorPage.imageCaption')}</Box>
        </Box>
        <Box style={styles.subTitle}>
          <Trans ns="common" i18nKey="errorPage.backToHomepage">
            Don't cry about it - Head back to the{' '}
            <Box as="a" href={homepageHref}>
              homepage
            </Box>
          </Trans>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
